
import logo from './assets/pksi-horizontal.jpg';
import lungs from './assets/lungs.jpg';
import usow from './assets/usow.jpg';
import solei from './assets/solei.jpg';

import lungsvid from './assets/lungs.mp4';
import usowvid from './assets/usow.mp4';
import soleivid from './assets/solei.mp4';

import './App.css';

function App() {

  let timeout1, timeout2;

  function handleMouseOver(e){
    const li = e.target.closest('li');
    // console.log(e);
    // console.log(li);
    const vid = li.getElementsByTagName('video')[0];
    // console.log(vid);
    const img = li.getElementsByTagName('img')[0];
    const src = vid.getElementsByTagName('source')[0];
    // console.log(src);
    const id = li.id;
    if (id==="lungs") {
      src.src = lungsvid;
    } else if (id==="usow") {
      src.src = usowvid;
    } else if (id==="solei") {
      src.src = soleivid;
    }
    vid.addEventListener('loadeddata',()=>{
      img.style.opacity = 0;
    });
    vid.load();
  }
  function handleMouseOut(e){
    const li = e.target.closest('li');
    const vid = li.getElementsByTagName('video')[0];
    vid.pause();
    const img = li.getElementsByTagName('img')[0];
    img.style.opacity = 1;

  }
  function handleMouseOverLogo(e){
    timeout1 = setTimeout(()=>{
      e.target.parentNode.classList.toggle('LogoShowing',true);
    }, 500);
    timeout2 = setTimeout(()=>{
      e.target.parentNode.classList.toggle('LogoAnimating',true);
    }, 550);
  }
  function handleMouseOutLogo(e){
    clearTimeout(timeout1);
    clearTimeout(timeout2);
    e.target.parentNode.classList.toggle('LogoShowing',false);
    e.target.parentNode.classList.toggle('LogoAnimating',false);
  }

  return (
    <>
      <div className="w-3/5 md:w-3/4 mx-auto md:h-screen md:flex md:items-center relative">
        
        <div className="group peer relative md:absolute w-full md:w-24 md:bottom-auto md:top-half right-0 md:-right-24 md:hover:mb-0 mix-blend-darken z-50 transition-all md:hover:w-72 md:hover:px-0 origin-bottom-right" onMouseEnter={handleMouseOverLogo} onMouseLeave={handleMouseOutLogo}>
          <img src={logo} alt="Plus K Studios Inc." className="" />
          <div className="md:absolute md:hidden text-center text-xs -mt-6">
            <h1>Awesome work and happy clients</h1>
            <p>Plus K is a highly collaborative, interdisciplinary interactive design and development studio specializing in interactive installations, games, microsites, banners and motion design. We pride ourselves on our philosophy of putting our clients’ and partners’ ideas first resulting in awesome, award-winning work and happy clients.</p>
            <p className="mt-2">5001 Yonge Street, suite 1505<br/>Toronto, ON M2N 6P6<br/><a href="tel:4169075629">(416) 907-5629</a></p>
            <p className="mt-2"><a href="mailto:newbusiness@plusk.com" className="arrow">Work with us</a></p>
          </div>
        </div>
        <ul className="w-full md:columns-3 gap-8 space-y-8 py-8 img-grid peer-hover:opacity-10 transition-all">
          <li onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} id="lungs">
            <video autoPlay loop muted playsInline><source/></video>
            <img src={lungs} alt="Lungs In The Air" className="" />
            <div className="">
              <h3>Lungs In The Air</h3>
              <i>Partner Agency: <strong>Performance Art</strong></i>
              <p>Particle system in threejs integrated with a React front-end.</p>
              <a href="https://lungsintheair.ca" target="_blank" rel="noreferrer" className="arrow">Visit site</a>
            </div>
          </li>
          <li onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} id="usow">
            <video autoPlay loop muted playsInline><source/></video>
            <img src={usow} alt="United State of Women" className="" />
            <div className="">
              <h3>United State of Women</h3>
              <i>Partner Agency: <strong>Unreasonable</strong></i>
              <p>Custom Wordpress theme with extensive content block system.</p>
              <a href="https://usow.org" target="_blank" rel="noreferrer" className="arrow">Visit site</a>
            </div>
          </li>
          <li onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} id="solei">
            <video autoPlay loop muted playsInline><source/></video>
            <img src={solei} alt="Solei" className="" />
            <div className="">
              <h3>Solei</h3>
              <i>Partner Agency: <strong>Cossette</strong></i>
              <p>Custom Wordpress theme.</p>
              <a href="https://solei.ca" target="_blank" rel="noreferrer" className="arrow">Visit site</a>
            </div>
          </li>
        </ul>
      </div>
    </> // end container
  );
}

export default App;
